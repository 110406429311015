import React from "react"
import styled from "styled-components"
import Layout from "../layout/layout"
import Fade from 'react-reveal/Fade';
import Card from "../../static/family_card.jpeg"

const Container = styled.div`
   width: 90%;
   /* min-height: 90vh; */
   position: relative;
   margin: 0 auto 3rem;
`;

const TextContainer = styled.div`
   display: grid;
   grid-template-columns: 50% 50%;
    grid-gap: 60px;
    @media (max-width: 960px) {
      grid-template-columns: 100%;
    }
`;

const ImageWrapper = styled.div`
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  img {
    width: 100%;
    height: auto;
  }
`;

export default function FamilyCard() {

  return (
    <Layout pageTitle="Family Card - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
      <Fade left duration={1000}>
        <h1>Family Card</h1>
      </Fade>
      <Container>
        <TextContainer>
          <p><b>Lo studio dentistico Dr. Eugenio Maucieri è fiera di presentare una nuova politica sociale volta a permettere alle famiglie numerose di poter sottoporre i propri figli alle cure odontoiatriche</b>, senza dover scegliere di affrontare in maniera parziale e selettiva i piani di terapia necessari a garantire una salute orale futura ai propri bambini.
            <br/><br/>
            Per la Family Card ci sono particolari agevolazioni nelle modalità di pagamento chiedere in segreteria.
        </p>
        <ImageWrapper>
        <img src={Card} alt="Family Card" />
        </ImageWrapper>
        {/* <p>
          <b>A partire dalla tessera verde,lo studio odontoiatrico Dr. Eugenio Maucieri lavorerà praticamente sottocosto al fine di poter garantire la cura paritaria di tutti i figli delle famiglie numerose</b>, perché è giusto che le generazioni future, nell’età in cui si creano le basi per la futura salute orale, possano affrontare le cure dentali senza dover subire il comprensibile peso per i genitori di dover scegliere a quali cure e a quale figlio dare la precedenza.
          <br/><br/>
          Certi di essere venuti incontro alle esigenze delle famiglie numerose, per quanto è nelle nostre possibilità, <b>invitiamo i genitori a richiedere le tessere per le cure future dei propri figli in segreteria</b>, dove riceveranno ulteriori delucidazioni sul regolamento di questa iniziativa sociale!
          <br/><br/>
          <i>*I vari tariffari di Pedodonzia e Ortodonzia sono consultabili a richiesta presso la segreteria della clinica.</i>
        </p> */}
        </TextContainer>
    
      </Container>
    </Layout>
  )
}